<template>
	<div id="chaxun">
		<!-- 选择专业查看可报考的大学与各学科覆盖率 -->
		<div class="left">
			<p class="title">选择专业查看可报考的大学与各学科覆盖率</p>
			<el-select v-model="value" placeholder="请选择">
				<el-option v-for="(item,index) in typeOptions" :key="item.text" :label="item.text" :value="index">
				</el-option>
			</el-select>
			<div class="pro" v-if="value != null">
				<div class="proItem" v-for="(item,index) in typeOptions[value].coverages" :key="item.name">
					<span>{{item.name}}</span>
					<el-progress :stroke-width="15" :percentage="item.coverage" :color="proColor[index]"></el-progress>
				</div>

			</div>
		</div>
		<!-- 大学排名 -->
		<div class="right orgBox">
			<p class="title">大学名称</p>
			<div class="college innerBox">
				<!-- 循环渲染每行 -->
				<div class="cell" v-for="(item,index) in college" :key="item.name">
					<div class="icon">
						<!-- 判断排名为1、2，它们两个图标与其它不同 -->
						<img v-if="index == 0" :src="topIcon">
						<img v-else-if="index == 1" :src="secondIcon">
						<img v-else :src="otherIcon">
						<span>{{index+1}}</span>
					</div>
					<p class="collegeName">{{item.name}}</p>
					<span class="value">{{item.num}}</span>
					<img class="right-icon" src="../../assets/mbti-next.png">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				//大学
				college: [{
						name: '清华大学',
						num: '1'
					},
					{
						name: '北京大学',
						num: '1'
					},
					{
						name: '清华大学',
						num: '1'
					},
					{
						name: '复旦大学',
						num: '1'
					},
					{
						name: '上海交通大学',
						num: '1'
					},
					{
						name: '中国人民大学',
						num: '1'
					},
					{
						name: '南开大学',
						num: '1'
					},
					{
						name: '浙江大学',
						num: '1'
					},
					{
						name: '南京大学',
						num: '1'
					},
					{
						name: '武汉大学',
						num: '1'
					},
					{
						name: '天津大学',
						num: '1'
					},
				],
				//选择专业
				typeOptions: [{
						text: '法学类',
						checked: false,
						coverages: [{
								name: '物理',
								coverage: 26.6
							},
							{
								name: '化学',
								coverage: 26.6
							},
							{
								name: '生物',
								coverage: 34.6
							},
							{
								name: '政治',
								coverage: 62
							},
							{
								name: '历史',
								coverage: 43
							},
							{
								name: '地理',
								coverage: 36
							},
						],
					},
					{
						text: '历史学类',
						checked: false,
						coverages: [{
								name: '物理',
								coverage: 26.6
							},
							{
								name: '化学',
								coverage: 46.6
							},
							{
								name: '生物',
								coverage: 34.6
							},
							{
								name: '政治',
								coverage: 52
							},
							{
								name: '历史',
								coverage: 73
							},
							{
								name: '地理',
								coverage: 36
							},
						],
					},
					{
						text: '海洋科类',
						checked: false,
						coverages: [{
								name: '物理',
								coverage: 26.6
							},
							{
								name: '化学',
								coverage: 26.6
							},
							{
								name: '生物',
								coverage: 64.6
							},
							{
								name: '政治',
								coverage: 52
							},
							{
								name: '历史',
								coverage: 53
							},
							{
								name: '地理',
								coverage: 66
							},
						],
					},
					{
						text: '文学类',
						checked: false,
						coverages: [{
								name: '物理',
								coverage: 26.6
							},
							{
								name: '化学',
								coverage: 26.6
							},
							{
								name: '生物',
								coverage: 34.6
							},
							{
								name: '政治',
								coverage: 62
							},
							{
								name: '历史',
								coverage: 63
							},
							{
								name: '地理',
								coverage: 36
							},
						],
					},
					{
						text: '计算机技术类',
						checked: false,
						coverages: [{
								name: '物理',
								coverage: 16.6
							},
							{
								name: '化学',
								coverage: 46.6
							},
							{
								name: '生物',
								coverage: 32.6
							},
							{
								name: '政治',
								coverage: 52
							},
							{
								name: '历史',
								coverage: 73
							},
							{
								name: '地理',
								coverage: 36
							},
						],
					},
				],
				//进度条颜色
				proColor: [
					'#fcc200',
					'#fe9900',
					'#33ccaa',
					'#fcc200',
					'#fe9900',
					'#33ccaa',
				],
				//图标
				topIcon: require('../../assets/top.png'),
				secondIcon: require('../../assets/second.png'),
				otherIcon: require('../../assets/other.png'),
				value: null,
			}
		},
		watch: {
		}
	}
</script>

<style scoped="scoped">
	#chaxun {
		min-height: 87%;
		background-color: white;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		display: flex;
		box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.1);
	}

	.left {
		width: 25%;
		margin: 20px;
		padding: 15px;
		text-align: left;
	}

	.left .title {
		padding-bottom: 20px;
		color: #fe9002;
	}

	.left .el-select {
		width: 100%;
	}

	>>>.left .el-select .el-input .el-input__inner {
		border: 1px solid #fe9002;
	}

	.left .pro {
		border-top: 1px solid #eee;
		margin-top: 30px;
	}

	.left .pro .proItem {
		display: flex;
		padding: 20px 0;
	}

	.left .pro .proItem span {
		width: 50px;
	}

	.left .pro .proItem .el-progress {
		flex: 1;
		display: flex;
	}

	.right {
		flex: 1;
		margin: 20px;
		padding: 15px;
	}

	
</style>
