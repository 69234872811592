<template>
	<el-dialog :visible.sync="centerDialogVisible" :before-close="close" width="70%" center>
		<div class="head">
			<img :src="uniInfo.logo">
			<div class="content">
				<p class="schoolName">{{uniInfo.schoolName}}</p>
				<div class="tags">
					<el-tag type="primary" size="small" v-for="tag in uniInfo.char" :key="tag">{{filter(tag)}}</el-tag>
				</div>
			</div>
		</div>
		<div class="content">
			<el-tabs v-model="activeName">
				<el-tab-pane label="院校主页" name="index">
					<div class="uniIndex">
						<div class="introduce">
							<h3>院校介绍</h3>
							<div class="inner">11123123</div>
							<el-button type="primary" size="small">查看完整介绍</el-button>
						</div>
						<div class="baseInfo">
							<h3>基础信息</h3>
							<div class="inner">
								<div class="cell" v-for="(item) in baseInfo" :key="item.label">
									<span class="title">{{item.label}}</span>
									<span class="content">{{item.content}}</span>
								</div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="院系设置" name="set">
					<el-table :data="faculty" border style="width: 100%;" v-loading="listLoading"
						:row-style="{height: '50px'}" height="calc(100% - 150px)">
						<el-table-column prop="name" label="学院" width="200px">
							<template slot-scope="scope">
								<span style="font-weight: bold;">{{scope.row.name}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="major" label="所含专业">
							<template slot-scope="scope">
								<span style="margin-right: 30px;" v-for="item in scope.row.major" :key="item">•
									&nbsp;{{item}}</span>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="招生章程" name="con">招生章程</el-tab-pane>
			</el-tabs>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				logo:'',
				centerDialogVisible: false,
				activeName: 'index',
				listLoading: false,
				baseInfo: [{
						label: '创建时间',
						content: '123456'
					},
					{
						label: '办学性质',
						content: '111'
					},
					{
						label: '批次',
						content: '111'
					},
					{
						label: '隶属部门',
						content: '111'
					},
					{
						label: '学校地址',
						content: '111'
					},
					{
						label: '招生电话',
						content: '111'
					},
					{
						label: '学校官网',
						content: '111'
					},
					{
						label: '招生网站',
						content: ''
					},
				],
				faculty: [{
					name: '基础教育学院-预科部',
					major: ['学前教育（本）', '少数民族预科（本）', '小学教育（本）']
				}],
			};
		},
		props: {
			dialogShow: Boolean,
			uniInfo: Object,
			charOptions:Array
		},
		watch: {
			dialogShow(nv) {
				this.centerDialogVisible = nv;
			},
		},
		methods: {
			close() {
				this.centerDialogVisible = false;
				this.$emit('closeUniinfo', false);
			},
			filter(val) {
				let item = this.charOptions.find(item => item.value == val);
				//console.log(item.label);
				if(item){
					return item.label;
				}else{
					return 'error'
				}
			},
		}
	};
</script>

<style lang="scss" scoped="">
	.head {
		padding: 10px 0;
		display: flex;
		font-size: 16px;

		img {
			width: 20%;
			padding: 0 10px;
		}

		.content {
			padding: 15px 0;
			.schoolName {
				font-size: 36px;
				margin-bottom: 15px;
			}

			.tags {
				.el-tag {
					margin: 0 5px;
				}
			}
		}
	}

	.content {
		.uniIndex {
			display: flex;

			.introduce {
				width: 65%;
				margin-right: 5%;

				.inner {
					padding: 10px;
				}
			}

			.baseInfo {
				flex: 1;

				.inner {
					.cell {
						border-bottom: 1px solid #eee;
						padding: 10px 0;
						display: flex;
						font-size: 16px;

						.title {
							width: 30%;
						}
						
						.content {
							flex: 1;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: #bbb;

						}
					}

				}
			}
		}
	}
</style>
