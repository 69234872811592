<template>
	<div id="mbti">
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane label="专业测评" name="zycp">
				<el-tabs v-model="zycpSelect" class="zycpTab" type="border-card" v-loading="loading">
					<!-- <el-tab-pane :label="item.label" :name="item.name" v-for="item in Tabpane" :key="item.name"> -->
					<el-tab-pane label="你感兴趣的活动" name="interested">
						<!-- 题目 -->
						<div class="left">
							<!-- <p class="qTitle">第一部分&nbsp;&nbsp;MBTI（共{{questions.length}}题）</p> -->
							<el-checkbox-group v-model="subjectTest.interestedAnswer">
								<el-tooltip class="item" effect="dark" :content="item.answerTitle" placement="top"
									v-for="item in subjectTest.interested" :key="item.id">
									<el-checkbox border :label="item.answerTitle">
									</el-checkbox>
								</el-tooltip>
							</el-checkbox-group>
							<el-button type="primary" plain @click="zycpSelect = 'competent'"
								style="width: 200px;position: absolute;right: 15px;bottom: 15px;">下一步</el-button>
						</div>
					</el-tab-pane>
					<el-tab-pane label="你所擅长或胜任的活动" name="competent">
						<!-- 题目 -->
						<div class="left">
							<!-- <p class="qTitle">第一部分&nbsp;&nbsp;MBTI（共{{questions.length}}题）</p> -->
							<el-checkbox-group v-model="subjectTest.competentAnswer">
								<el-tooltip class="item" effect="dark" :content="item.answerTitle" placement="top"
									v-for="item in subjectTest.competent" :key="item.id">
									<el-checkbox border :label="item.answerTitle">
									</el-checkbox>
								</el-tooltip>
							</el-checkbox-group>
							<el-button type="primary" plain @click="zycpSelect = 'likeJob'"
								style="width: 200px;position: absolute;right: 15px;bottom: 15px;">下一步</el-button>
						</div>

					</el-tab-pane>
					<el-tab-pane label="你所喜欢的职业" name="likeJob">
						<!-- 题目 -->
						<div class="left">
							<!-- <p class="qTitle">第一部分&nbsp;&nbsp;MBTI（共{{questions.length}}题）</p> -->
							<el-checkbox-group v-model="subjectTest.likeJobAnswer">
								<el-tooltip class="item" effect="dark" :content="item.answerTitle" placement="top"
									v-for="item in subjectTest.likeJob" :key="item.id">
									<el-checkbox border :label="item.answerTitle">
									</el-checkbox>
								</el-tooltip>
							</el-checkbox-group>
							<el-button type="primary" plain @click="submit(0)"
								style="width: 200px;position: absolute;right: 15px;bottom: 15px;">提交</el-button>
						</div>
					</el-tab-pane>
				</el-tabs>
			</el-tab-pane>
			<el-tab-pane label="性格测试" name="second">
				<el-tabs v-model="mbtiSelect" class="zycpTab" type="border-card" v-loading="loading">
					<!-- <el-tab-pane :label="item.label" :name="item.name" v-for="item in Tabpane" :key="item.name"> -->
					<el-tab-pane label="第一维度" name="firstDim">
						<!-- 题目 -->
						<div class="left">
							<!-- <p class="qTitle">第一部分&nbsp;&nbsp;MBTI（共{{questions.length}}题）</p> -->
							<el-checkbox-group v-model="mbtiTest.firstDimAnswer">
								<el-tooltip class="item" effect="dark" :content="item.answerTitle" placement="top"
									v-for="item in mbtiTest.firstDim" :key="item.id">
									<el-checkbox border :label="item.answerTitle">
									</el-checkbox>
								</el-tooltip>
							</el-checkbox-group>
							<el-button type="primary" plain @click="mbtiSelect = 'secondDim'"
								style="width: 200px;position: absolute;right: 15px;bottom: 15px;">下一步</el-button>
						</div>
					</el-tab-pane>
					<el-tab-pane label="第二维度" name="secondDim">
						<!-- 题目 -->
						<div class="left">
							<!-- <p class="qTitle">第一部分&nbsp;&nbsp;MBTI（共{{questions.length}}题）</p> -->
							<el-checkbox-group v-model="mbtiTest.secondDimAnswer">
								<el-tooltip class="item" effect="dark" :content="item.answerTitle" placement="top"
									v-for="item in mbtiTest.secondDim" :key="item.id">
									<el-checkbox border :label="item.answerTitle">
									</el-checkbox>
								</el-tooltip>
							</el-checkbox-group>
							<el-button type="primary" plain @click="mbtiSelect = 'thirdDim'"
								style="width: 200px;position: absolute;right: 15px;bottom: 15px;">下一步</el-button>
						</div>

					</el-tab-pane>
					<el-tab-pane label="第三维度" name="thirdDim">
						<!-- 题目 -->
						<div class="left">
							<!-- <p class="qTitle">第一部分&nbsp;&nbsp;MBTI（共{{questions.length}}题）</p> -->
							<el-checkbox-group v-model="mbtiTest.thirdDimAnswer">
								<el-tooltip class="item" effect="dark" :content="item.answerTitle" placement="top"
									v-for="item in mbtiTest.thirdDim" :key="item.id">
									<el-checkbox border :label="item.answerTitle">
									</el-checkbox>
								</el-tooltip>
							</el-checkbox-group>
							<el-button type="primary" plain @click="mbtiSelect = 'fourthDim'"
								style="width: 200px;position: absolute;right: 15px;bottom: 15px;">下一步</el-button>
						</div>
					</el-tab-pane>
					<el-tab-pane label="第四维度" name="fourthDim">
						<!-- 题目 -->
						<div class="left">
							<!-- <p class="qTitle">第一部分&nbsp;&nbsp;MBTI（共{{questions.length}}题）</p> -->
							<el-checkbox-group v-model="mbtiTest.fourthDimAnswer">
								<el-tooltip class="item" effect="dark" :content="item.answerTitle" placement="top"
									v-for="item in mbtiTest.fourthDim" :key="item.id">
									<el-checkbox border :label="item.answerTitle">
									</el-checkbox>
								</el-tooltip>
							</el-checkbox-group>
							<el-button type="primary" plain @click="submit(1)"
								style="width: 200px;position: absolute;right: 15px;bottom: 15px;">提交</el-button>
						</div>
					</el-tab-pane>
				</el-tabs>
			</el-tab-pane>
			<el-tab-pane label="查看初步分析报告" name="third">查看初步分析报告</el-tab-pane>
			<el-tab-pane label="补充信息" name="fourth">补充信息</el-tab-pane>
			<el-tab-pane label="查看最终分析报告" name="fifth">查看最终分析报告</el-tab-pane>
		</el-tabs>

	</div>
</template>

<script>
	import * as mbtiItemss from '@/api/mbtiitemss'
	import * as subjectTestItems from '@/api/subjecttestitems'
	import * as categorys from '@/api/categorys'
	export default {
		data() {
			return {
				loading: false,
				activeName: 'zycp',
				zycpSelect: 'interested',
				mbtiSelect: 'firstDim',
				// 题目数据
				//list: [],
				subjectTest: {
					interested: [],
					competent: [],
					likeJob: [],
					interestedAnswer: [],
					competentAnswer: [],
					likeJobAnswer: [],
				},
				mbtiTest: {
					firstDim: [],
					secondDim: [],
					thirdDim: [],
					fourthDim: [],
					firstDimAnswer: [],
					secondDimAnswer: [],
					thirdDimAnswer: [],
					fourthDimAnswer: [],
				},
				answerCard: [],
				gategoryList: [],
				listQuery: { // 查询条件
					page: 1,
					limit: 20,
					key: undefined,
					appId: undefined,
					syS_TestQuestion: '', // 问题
					syS_TestAnswerType: '', // 答案选项类型
					answerTitle: '', // 答案选线内容
					minOrders: '', // 最小序号
					maxOrders: '', // 最大序号

				},
				temp: {
					id: '', // ID
					syS_TestQuestion: '', // 问题
					syS_TestAnswerType: '', // 答案选项类型
					answerTitle: '', // 答案选线内容
					orders: '', // 序号
					extendInfo: '' // 其他信息,防止最后加逗号，可以删除
				},
			}
		},
		created() {
			this.getGategorys();
			this.getSubjectList();
		},
		watch: {
			activeName(val){
				if(val == 'second'){
					this.getMbtiList();
				}
			}
		},
		methods: {
			//提交按钮点击
			submit(index) {
				this.answerCard = [];
				if (index == 0) {
					this.answerCard = this.answerCard.concat(this.subjectTest.interestedAnswer, this.subjectTest
						.competentAnswer, this.subjectTest.likeJobAnswer);
				} else {
					this.answerCard = this.answerCard.concat(this.mbtiTest.firstDimAnswer, this.mbtiTest
						.secondDimAnswer, this.mbtiTest.thirdDimAnswer, this.mbtiTest.fourthDimAnswer);
				}
				//console.log(sindex,this.questions[index].options[sindex].length);

				this.$notify({
					title: '成功',
					message: '提交成功',
					type: 'success',
					duration: 2000
				})
				console.log(this.answerCard);
			},
			getSubjectList() {
				//this.list = [];
				for (let i in this.subjectTest) {
					this.subjectTest[i] = [];
				}
				this.loading = true;
				let listQuery = {
					limit: 999,
					mobileTel:this.$store.state.stuInfo.mobileTel
				}
				console.log(listQuery);
				subjectTestItems.getList(listQuery).then(response => {
					//console.log(response);
					let list1 = response.data;
					for (let i = 0; i < list1.length; i++) {
						var o = list1[i];
						// this.$set(o, 'show', false);
						if (o.syS_TestQuestion == '1') {
							this.subjectTest.interested.push(o);
						} else if (o.syS_TestQuestion == '2') {
							this.subjectTest.competent.push(o);
						} else {
							this.subjectTest.likeJob.push(o);
						}
						//this.list.push(o);
					}
					// this.subjectTest.interested.sort((x, y) => { //排序数组
					// 	return x.orders - y.orders;
					// });
					// this.subjectTest.competent.sort((x, y) => { //排序数组
					// 	return x.orders - y.orders;
					// });
					// this.subjectTest.likeJob.sort((x, y) => { //排序数组
					// 	return x.orders - y.orders;
					// });
					this.total = response.count
					this.loading = false
					//console.log(this.subjectTest.interested);
				})

			},
			getMbtiList() {
				this.loading = true;
				for (let i in this.mbtiTest) {
					this.mbtiTest[i] = [];
				}
				let listQuery = {
					limit: 999,
					mobileTel:this.$store.state.stuInfo.mobileTel
				}
				
				mbtiItemss.getList(listQuery).then(response => {
					let list1 = response.data;
					for (let i = 0; i < list1.length; i++) {
						var o = list1[i];
						// this.$set(o, 'show', false);
						if (o.syS_MBTIDimension == '1') {
							this.mbtiTest.firstDim.push(o);
						} else if (o.syS_MBTIDimension == '2') {
							this.mbtiTest.secondDim.push(o);
						} else if (o.syS_MBTIDimension == '3') {
							this.mbtiTest.thirdDim.push(o);
						} else {
							this.mbtiTest.fourthDim.push(o);
						}
						//this.list.push(o);
					}
					this.total = response.count
					this.loading = false
				})

			},
			getGategorys() {
				let listQuery = {
					page: 1,
					limit: 9999
				};
				console.log('getcategorys')
				categorys.getList(listQuery).then(res => {
					this.gategoryList = res.data
					console.log('getcategorys2')
					//for(let key in this.gategoryList){
					//    console.log(this.gategoryList[key])
					//}
					console.log('getcategorys3')
				})
			},
			getGategoryName(val, columnName) {

				var dicobject = this.gategoryList.find(t => t.typeId.toUpperCase() == columnName.toUpperCase() && t
					.dtValue == val)
				//var dicobject = this.gategoryList.find(t => { 
				//  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) { 
				//    return t
				//  } 
				//});

				if (!dicobject) {
					return ''
				} else {
					return dicobject.name
				}

			},
		}
	}
</script>

<style>
	.el-tabs__item.is-active,
	.el-tabs__item:hover:hover {
		color: rgb(255, 50, 37);
	}

	.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active,
	.el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
		color: rgb(255, 50, 37);
	}
</style>
<style scoped="scoped">
	#mbti {
		background-color: white;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.1);
	}

	.zycpTab .el-tab-pane {
		height: 100%;
	}

	.left {
		position: relative;
		text-align: left;
		padding: 15px 20px;
	}


	/* .left .qTitle {
		color: #fe9002;
		font-weight: bold;
		padding-bottom: 10px;
	} */
	.left .el-checkbox {
		margin: 25px 10px;
		width: 200px;
	}

	>>>.el-checkbox__input {
		bottom: 4px;
	}

	>>>.el-checkbox__label {
		width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	/* 选择框样式修改 */
	>>>.el-checkbox.is-bordered.is-checked {
		border-color: #fe9002;
	}

	>>>.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #fe9002;
		border-color: #fe9002;
	}

	>>>.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #fe9002;
	}

	.left .item .title {
		padding-bottom: 20px;
	}

	.left .item .title .qType {
		color: #fe9002;
	}

	.left .item .option p {
		padding-bottom: 10px;
	}

	.left .box-card {
		margin-bottom: 15px;
	}

	.left .box-card .title {
		padding-bottom: 20px;
	}

	.left .box-card .title .qType {
		color: #fe9002;
	}

	.left .box-card .option p {
		padding-bottom: 10px;
	}

	/* 答题卡样式 */
	.right {
		flex: 1;
	}

	.awrsheetBox {
		margin: 20px;
		box-shadow: 5px 5px 5px #eee;
		border: 1px solid #fe9002;
		border-radius: 5px;
		background-color: #fefaf4;
	}

	.awrsheetBox .title {
		padding: 7px 10px;
		background-color: #fe9002;
		color: white;
		text-align: left;
		font-weight: bold;
	}

	.awrsheetBox .function {
		padding: 5px 10px;

	}

	.useTime {
		padding: 20px 0;
	}

	.awrsheetBox .function .time {
		font-size: 24px;
	}

	.awrsheetBox .function .el-button {
		width: 100%;
		background-color: #33ccaa;
		margin-bottom: 10px;
		border: none;
	}

	.awrsheetBox .ansSheet .title {
		color: #fe9002;
		background-color: #f8e9cf;
		font-weight: normal;
	}

	.awrsheetBox .ansSheet .options {
		margin: 15px;
		padding: 5px;
		height: 200px;
	}

	.awrsheetBox .ansSheet .options div {
		padding: 5px;
		margin: 10px 6px;
		width: 20px;
		height: 20px;
		background-color: #f8f8f8;
		border: 1px solid #dddddd;
		float: left;
		cursor: pointer;
	}

	.awrsheetBox .ansSheet .options .active {
		color: white;
		background-color: #fe9002;
		border: none;
	}

	.awrsheetBox .ansSheet .explain {
		padding: 10px;
		margin: 10px;
		display: flex;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;
	}

	.awrsheetBox .ansSheet .explain .true,
	.awrsheetBox .ansSheet .explain .false {
		display: flex;
		line-height: 30px;
	}

	.awrsheetBox .ansSheet .explain .true div {
		width: 20px;
		height: 20px;
		margin: 5px 10px;
		background-color: #fe9002;
	}

	.awrsheetBox .ansSheet .explain .false div {
		width: 20px;
		height: 20px;
		margin: 5px 10px;
		background-color: #f8f8f8;
		border: 1px solid #dddddd;
	}

	.awrsheetBox .ansSheet .el-button {
		width: calc(100% - 20px);
		background-color: #fe9002;
		margin: 10px;
		border: none;
	}
</style>
