<template>
	<el-dialog :visible.sync="centerDialogVisible" :before-close="close" width="70%" center>
		<div class="uniIndex">
			<div class="introduce">
				<h3>哲学</h3>
				<div class="inner">
					<div class="top">
						<el-descriptions border>
							<el-descriptions-item label="专业代码">kooriookami</el-descriptions-item>
							<el-descriptions-item label="所属学科">18100000000</el-descriptions-item>
							<el-descriptions-item label="相近专业">苏州市</el-descriptions-item>
							<el-descriptions-item label="新高考学科要求">11</el-descriptions-item>
							<el-descriptions-item label="职业兴趣匹配">kooriookami</el-descriptions-item>
							<el-descriptions-item label="男女比例">18100000000</el-descriptions-item>
							<el-descriptions-item label="性别要求">苏州市</el-descriptions-item>
							<el-descriptions-item label="薪资水平">11</el-descriptions-item>
						</el-descriptions>
					</div>
					<div class="bottom">
						<h3>专业介绍</h3>
						<div class="content"></div>
					</div>
				</div>
			</div>
			<div class="baseInfo">
				<h3>推荐大学</h3>
				<div class="inner">
					<div class="cell" v-for="(item) in school" :key="item.schoolName">
						<img :src="item.logo">
						<div class="content">
							<p class="schoolName">{{item.schoolName}}</p>
							<div class="tags">
								<el-tag type="danger" size="small" v-for="tag in item.tags" :key="tag.value">{{tag.label}}</el-tag>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				centerDialogVisible: false,
				school: [
					{
						logo:'',
						schoolName: '清华大学',
						tags:[
							{
								value:'1',
								label:'211'
							},
							{
								value:'2',
								label:'985'
							},
							{
								value:'3',
								label:'双一流'
							},
						]
					},
					{
						logo:'',
						schoolName: '清华大学',
						tags:[
							{
								value:'1',
								label:'211'
							},
							{
								value:'2',
								label:'985'
							},
							{
								value:'3',
								label:'双一流'
							},
						]
					},
					{
						logo:'',
						schoolName: '清华大学',
						tags:[
							{
								value:'1',
								label:'211'
							},
							{
								value:'2',
								label:'985'
							},
							{
								value:'3',
								label:'双一流'
							},
						]
					},
					{
						logo:'',
						schoolName: '清华大学',
						tags:[
							{
								value:'1',
								label:'211'
							},
							{
								value:'2',
								label:'985'
							},
							{
								value:'3',
								label:'双一流'
							},
						]
					},
					{
						logo:'',
						schoolName: '清华大学',
						tags:[
							{
								value:'1',
								label:'211'
							},
							{
								value:'2',
								label:'985'
							},
							{
								value:'3',
								label:'双一流'
							},
						]
					},
					{
						logo:'',
						schoolName: '清华大学',
						tags:[
							{
								value:'1',
								label:'211'
							},
							{
								value:'2',
								label:'985'
							},
							{
								value:'3',
								label:'双一流'
							},
						]
					},
					{
						logo:'',
						schoolName: '清华大学',
						tags:[
							{
								value:'1',
								label:'211'
							},
							{
								value:'2',
								label:'985'
							},
							{
								value:'3',
								label:'双一流'
							},
						]
					},
					{
						logo:'',
						schoolName: '清华大学',
						tags:[
							{
								value:'1',
								label:'211'
							},
							{
								value:'2',
								label:'985'
							},
							{
								value:'3',
								label:'双一流'
							},
						]
					},
				],
			};
		},
		props: {
			dialogShow: Boolean,
			uniInfo: Object
		},
		watch: {
			dialogShow(nv) {
				this.centerDialogVisible = nv;
			},

		},
		methods: {
			close() {
				this.centerDialogVisible = false;
				this.$emit('closeUniinfo', false);
			}
		}
	};
</script>

<style lang="scss" scoped="">
	.uniIndex {
		display: flex;
		max-height: 500px;
		overflow-y: scroll;
		.introduce {
			width: 65%;
			margin-right: 5%;

			.inner {
				padding: 10px;
				.el-descriptions{
					width: 100%;
					.el-descriptions-item{
						color: #409eff;
					}
				}
				.bottom{
					.content{
						overflow: hidden;
						padding: 10px 20px;
					}
				}
			}
		}

		.baseInfo {
			flex: 1;

			.inner {
				.cell {
					border-bottom: 1px solid #eee;
					padding: 10px 0;
					display: flex;
					font-size: 16px;
					
					img {
						width: 30%;
						padding: 0 10px;
					}

					.content {
						.schoolName{
							color: #409eff;
							margin-bottom: 5px;
						}
						.tags{
							.el-tag{
								margin: 0 5px;
							}
						}
					}
				}

			}
		}
	}
</style>
