<template>
	<div id="xuanke">
		<!-- 选科 -->
		<div class="left">
			<p class="title">您选择的学科</p>
			<div class="items">
				<!-- 学科按钮 -->
				<div class="item btn" v-for="(item,index) in course" :key="item.text"
					:class="{activeBtn : item.checked}" @click="isChecked(index)">
					{{item.text}}
				</div>
			</div>
			<!-- 提交按钮 -->
			<el-button @click="sumbit()">确定</el-button>
		</div>
		<!-- 可报考专业 -->
		<div class="right orgBox">
			<p class="title">可报考专业</p>
			<div class="major innerBox">
				<!-- 循环渲染每行 -->
				<div class="cell" v-for="(item,index) in major" :key="item.name">
					<div class="icon">
						<!-- 判断排名为1、2，它们两个图标与其它不同 -->
						<img v-if="index == 0" :src="topIcon">
						<img v-else-if="index == 1" :src="secondIcon">
						<img v-else :src="otherIcon">
						<span>{{index+1}}</span>
					</div>
					<p class="title">{{item.name}}</p>
					<span class="value">{{item.score}}</span>
					<img class="right-icon" src="../assets/mbti-next.png">
				</div>
				<!-- <van-cell v-for="(item,index) in major" :title="item.name" :value="item.score" :icon="item.icon" is-link /> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				// 学科
				course: [{
						text: '物理',
						checked: false
					},
					{
						text: '化学',
						checked: false
					},
					{
						text: '生物',
						checked: false
					},
					{
						text: '政治',
						checked: false
					},
					{
						text: '历史',
						checked: false
					},
					{
						text: '地理',
						checked: false
					},
				],
				// 专业
				major: [{
						name: '管理学',
						score: '451'
					},
					{
						name: '文学',
						score: '451'
					},
					{
						name: '艺术学',
						score: '451'
					},
					{
						name: '经济学',
						score: '451'
					},
					{
						name: '法学',
						score: '451'
					},
					{
						name: '教育学',
						score: '451'
					},
					{
						name: '工学',
						score: '451'
					},
				],
				// 图标
				topIcon: require('../assets/top.png'),
				secondIcon: require('../assets/second.png'),
				otherIcon: require('../assets/other.png'),
			}
		},
		methods: {
			// 单击学科按钮
			isChecked(index) {
				this.course[index].checked = !this.course[index].checked;
			},
			// 单击提交按钮
			sumbit() {
				let choiceArr = [];
				for (let i = 0; i < this.course.length; i++) {
					if (this.course[i].checked) {
						choiceArr.push(this.course[i].text);
					}
				}
				this.$alert(choiceArr.join(' '),'当前选择专业');
			}
		}
	}
</script>


<style scoped="scoped">
	#xuanke {
		min-height: 87%;
		background-color: white;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		display: flex;
		box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.1);
	}

	.left {
		width: 28%;
		margin: 20px;
		padding: 15px;
		text-align: left;
	}
	
	.left .title {
		padding-bottom: 20px;
		color: #fe9002;
	}
	
	.items{
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #eee;
	}
	
	.left .el-button {
		width: 100%;
		border: none;
		background-color: #fe9002;
		color: white;
	}

	.left .el-button:hover {
		background-color: #ffb95e;

	}

	.right {
		flex: 1;
		margin: 20px;
		padding: 15px;
	}
</style>
