<template>
	<div class="uniSearch">
		<div class="top">
			<h3>专业信息查询</h3>
			<el-button type="primary" icon="el-icon-plus" size="small">推送</el-button>
		</div>
		<div class="bottom">
			<div class="infoSearch">
				<el-row>
					<el-col :span="6">
						<el-input prefix-icon="el-icon-search" size="small" placeholder="专业名称"
							v-model="search.searchName" class="searchName">
						</el-input>
					</el-col>
					<el-col :span="6">
						<el-input prefix-icon="el-icon-search" size="small" placeholder="学科门类"
							v-model="search.subjectCate" class="subjectCate">
						</el-input>
					</el-col>
					<el-col :span="6">
						<el-input prefix-icon="el-icon-search" size="small" placeholder="专业门类"
							v-model="search.majorCate" class="majorCate">
						</el-input>
					</el-col>
				</el-row>
			</div>
			<el-table :data="list" stripe border style="width: 100%;" v-loading="listLoading" :row-style="{height: '50px'}"
				height="calc(100% - 100px)">
				<el-table-column type="selection"></el-table-column>
				<el-table-column prop="majorName" label="专业名称"></el-table-column>
				<el-table-column prop="subjectCate" label="学科门类" width="300"></el-table-column>
				<el-table-column prop="majorCate" label="专业门类"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-link type="primary" :underline="false" @click="check(scope.$index)">查看</el-link>
					</template>
				</el-table-column>
			</el-table>
			<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
				@pagination="handleCurrentChange" />
			<majorInfo :dialogShow="majorInfoShow" @closeUniinfo="closeUniinfo"></majorInfo>
		</div>
	</div>
</template>

<script>
	//import * as categorys from '@/api/categorys'
	//import AuthSelect from '../../components/AuthSelect.vue'
	import Pagination from '@/components/Pagination'
	import majorInfo from './majorInfo.vue'
	export default {
		components: {
			Pagination,
			//AuthSelect,
			majorInfo
		},
		data() {
			return {
				majorInfoShow: false,
				props: {
					multiple: true
				},
				listLoading: false,
				listQuery: { // 查询条件
					page: 1,
					limit: 20,
					key: undefined
				},
				search: {
					searchName: '',
					subjectCate:'',
					majorCate:''
					
				},
				charOptions: [],
				list: [],
				searchList: [],
			}
		},
		created() {
			this.getList();
		},
		methods: {
			check(index){
				console.log(index);
				this.majorInfoShow = true;
			},
			closeUniinfo(val) {
				this.uniInfoShow = val;
			},
			// 通过值获取名称
			filter(val) {
				let item = this.charOptions.find(item => item.value == val);
				//console.log(item.label);
				return item.label;
			},
			handleCurrentChange(val) {
				//console.log(val);
				this.listQuery.page = val.page
				this.listQuery.limit = val.limit
				this.list = []
				this.getList();
			},
			getList() {
				this.listLoading = true;
				this.list = [
					{
						majorName:'哲学',
						subjectCate:'哲学',
						majorCate:'哲学类'
					},
					{
						majorName:'哲学',
						subjectCate:'哲学',
						majorCate:'哲学类'
					},
					{
						majorName:'哲学',
						subjectCate:'哲学',
						majorCate:'哲学类'
					},
					{
						majorName:'哲学',
						subjectCate:'哲学',
						majorCate:'哲学类'
					},
					{
						majorName:'哲学',
						subjectCate:'哲学',
						majorCate:'哲学类'
					},
				]
				this.listLoading = false;
			}
		},
		computed: {
			total() {
				return this.list.length;
			},
		},
		watch: {
			search: {
				handler() {
					this.getList();
				},
				deep: true
			},

		}
	}
</script>

<style lang="scss" scoped>
	.uniSearch {
		background-color: white;
		text-align: left;
		height: calc(100% - 110px);

		.top {
			padding: 5px 25px;
			border-bottom: 1px solid #eee;
			display: flex;
			line-height: 34px;

			h3 {
				margin: 0;
				flex: 1;
			}
		}

		.bottom {
			background: none;
			height: calc(100% - 50px);
			padding: 20px 10px;

			.infoSearch {
				margin-bottom: 20px;

				//display: flex;
				.searchName,
				.subjectCate,
				.majorCate {
					width: 70%;
				}
			}

			.el-table {
				.el-tag {
					margin-right: 5px;
				}
			}
		}
	}
</style>
