<template>
	<div class="uniSearch">
		<div class="top">
			<h3>院校信息查询</h3>
			<el-button type="primary" icon="el-icon-plus" size="small">推送</el-button>
		</div>
		<div class="bottom">
			<div class="infoSearch">
				<el-row>
					<el-col :span="6">
						<el-input prefix-icon="el-icon-search" size="small" :placeholder="'院校名称'"
							v-model="search.searchName" class="searchName">
						</el-input>
					</el-col>
					<el-col :span="6">
						<!-- 选择器多选 -->
						<AuthSelect :isEdit="true" :data-source="'SYS_SP'" v-model="search.searchChar"
							:placeholder="'院校特色'" class="searchChar" :size="'small'" :multiple="true"
							:collapseTags="true"></AuthSelect>
						<!-- <el-select v-model="search.searchChar" placeholder="院校特色" class="searchChar" size="small"
							multiple collapse-tags>
							<el-option v-for="item in searchOption.char" :key="item" :label="item" :value="item">
							</el-option>
						</el-select> -->
					</el-col>
					<el-col :span="6">
						<el-select v-model="search.searchProvince" placeholder="院校城市" class="searchProvince"
							size="small">
							<el-option v-for="item in searchOption.province" :key="item" :label="item" :value="item">
							</el-option>
						</el-select>
					</el-col>
					<el-col :span="6">
						<el-select v-model="search.searchCity" placeholder="院校省份" class="searchCity" size="small">
							<el-option v-for="item in city" :key="item.code" :label="item.address" :value="item.code">
							</el-option>
						</el-select>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<!-- <el-select v-model="search.searchType" placeholder="院校类别" class="searchType" size="small">
							<el-option v-for="item in searchOption.type" :key="item" :label="item" :value="item">
							</el-option>
						</el-select>						 -->
						<AuthSelect :placeholder="'请选择类型'" :isEdit="true" :data-source="'SYS_SchoolType'"
							v-model="search.searchType" size="small">
						</AuthSelect>
					</el-col>
					<el-col :span="6">
						<!-- <el-select v-model="search.searchLevel" placeholder="院校层次" class="searchLevel" size="small">
							<el-option v-for="item in searchOption.level" :key="item" :label="item" :value="item">
							</el-option>
						</el-select> -->
						<AuthSelect :placeholder="'请选择院校层次'" :isEdit="true" :data-source="'SYS_Level'"
							v-model="search.searchLevel" size="small">
						</AuthSelect>
					</el-col>
					<el-col :span="6">
						<el-select v-model="search.searchNature" placeholder="院校性质" class="searchNature" size="small">
							<el-option v-for="item in searchOption.nature" :key="item" :label="item" :value="item">
							</el-option>
						</el-select>
					</el-col>
				</el-row>
			</div>
			<el-table :data="list" border style="width: 100%" v-loading="listLoading" :row-style="{ height: '50px' }"
				height="calc(100% - 150px)">
				<el-table-column type="selection"></el-table-column>
				<el-table-column prop="logo" label="院校LOGO">
					<template slot-scope="scope">
						<img :src="scope.row.logo" width="40px" />
					</template>
				</el-table-column>
				<el-table-column prop="schoolName" label="院校名称" width="200px">
					<template slot-scope="scope">
						<el-link :underline="false" type="primary" @click="UniInfoshow(scope.$index)">{{scope.row.schoolName}}</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="char" label="院校特色" width="200px" v-if="charOptions.length != 0">
					<template slot-scope="scope">
						<el-tag size="small" effect="light" v-for="item in scope.row.char" :key="item">
							{{ filter(item) }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="tag" label="艺考标签" width="200px">
					<template slot-scope="scope">
						<el-tag size="small" effect="light" v-for="item in scope.row.tag" :key="item">{{ item }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="time" label="办学时间" width="100px"></el-table-column>
				<el-table-column prop="type" label="院校类别" width="100px"></el-table-column>
				<el-table-column prop="level" label="院校层次" width="100px"></el-table-column>
				<el-table-column prop="nature" label="院校性质" width="100px"></el-table-column>
			</el-table>
			<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
				@pagination="handleCurrentChange" />
			<UniInfo :dialogShow="uniInfoShow" @closeUniinfo="closeUniinfo" :uniInfo="temp" :charOptions="charOptions"></UniInfo>
		</div>
	</div>
</template>

<script>
	import * as categorys from "@/api/categorys";
	import AuthSelect from "@/components/AuthSelect.vue";
	import Pagination from "@/components/Pagination";
	import {
		cityData
	} from "@/components/cityData.js";
	import UniInfo from './uniInfo.vue'
	export default {
		components: {
			Pagination,
			AuthSelect,
			UniInfo,
		},
		data() {
			return {
				uniInfoShow: false,
				props: {
					multiple: true,
				},
				listLoading: false,
				city: [],
				search: {
					province: "", //省份
					searchName: "",
					searchChar: [],
					searchProvince: "",
					searchCity: "",
					searchType: "",
					searchLevel: "",
					searchNature: "",
				},
				listQuery: {
					// 查询条件
					page: 1,
					limit: 20,
					key: undefined,
					appId: undefined,
					province: "", // 省份
					syS_SchoolType: "", // 类型
					diC_KeySchool: [], // 重点院校
					syS_Level: "", // 院校层次
					syS_ChanceType: "", // 机会点类型
					city: "", // 城市
					schoolName: "", // 学校名称
					schoolCode: "", // 学校代码
					minSchoolRanking: "", // 最小排名
					maxSchoolRanking: "", // 最大排名
					syS_AdmissoinRule: "", // 录取规则
					schooUrl: "", // 网址
					syS_CreateType: "", // 办学方式
					minCreateYear: "", // 最小创办年份
					maxCreateYear: "", // 最大创办年份
					minStutents: "", // 最小学生数量
					maxStutents: "", // 最大学生数量
					minDoctorPoints: "", // 最小博士点
					maxDoctorPoints: "", // 最大博士点
					minMasterPoints: "", // 最小硕士点
					maxMasterPoints: "", // 最大硕士点
				},
				searchOption: {
					char: ["1", "2", "3"],
					province: [],
					city: [],
					cype: [],
					level: [],
					nature: [],
				},
				charOptions: [],
				list: [],
				searchList: [],
				uniList: [{
						logo: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fgkzy100.oss-cn-beijing.aliyuncs.com%2Farticle%2F3_null%2FriWY2irTT4.jpg&refer=http%3A%2F%2Fgkzy100.oss-cn-beijing.aliyuncs.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1650531624&t=62ba4be55f9dbdcee617d51958b5d086",
						schoolName: "北京大学",
						char: ["1", "2", "3"],
						tag: [],
						time: "1898",
						type: "综合类",
						level: "本科",
						nature: "公办",
					},
					{
						logo: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fgkzy100.oss-cn-beijing.aliyuncs.com%2Farticle%2F3_null%2FriWY2irTT4.jpg&refer=http%3A%2F%2Fgkzy100.oss-cn-beijing.aliyuncs.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1650531624&t=62ba4be55f9dbdcee617d51958b5d086",
						schoolName: "测试大学",
						char: ["2", "3"],
						tag: [],
						time: "1898",
						type: "综合类",
						level: "本科",
						nature: "公办",
					},
				],
				temp:{},
			};
		},
		created() {
			this.getList();
			this.getDiclist();
			this.city = this.getAreaList(this.search.province);
		},
		methods: {
			UniInfoshow(index) {
				//console.log(index);
				this.temp = this.uniList[index];
				this.uniInfoShow = true;
			},
			closeUniinfo(val) {
				this.uniInfoShow = val;
			},
			// 获取"院校特色"字典列表
			getDiclist() {
				let listQuery = {
					page: 1,
					limit: 9999,
					TypeId: "DIC_KeySchool",
				};
				categorys.getList(listQuery).then((res) => {
					this.charOptions = res.data.map((item) => {
						var o = {
							label: item.name,
							value: item.dtValue,
						};
						return o;
						//this.charOptions.push(o);
					});
					//console.log(this.charOptions);
				});
			},
			// 通过值获取名称
			filter(val) {
				let item = this.charOptions.find(item => item.value == val);
				//console.log(item.label);
				if (item) {
					return item.label;
				} else {
					return 'notFind'
				}
			},
			handleCurrentChange(val) {
				//console.log(val);
				this.listQuery.page = val.page;
				this.listQuery.limit = val.limit;
				this.list = [];
				setTimeout(() => {
					this.pageFn();
				});
			},
			pageFn() {
				this.list = this.searchList.slice(
					(this.listQuery.page - 1) * this.listQuery.limit,
					this.listQuery.page * this.listQuery.limit
				);
			},
			getList() {
				this.listLoading = true;
				this.list = [];
				this.searchList.splice(0, this.searchList.length);
				//
				if (
					this.search.searchName == "" &&
					this.search.searchProvince == "" &&
					this.search.searchChar.length == 0 &&
					this.search.searchCity == "" &&
					this.search.searchType == "" &&
					this.search.searchLevel == "" &&
					this.search.searchNature == ""
				) {
					this.searchList.splice(0, this.searchList.length);
					for (let i = 0; i < this.uniList.length; i++) {
						this.searchList.push(this.uniList[i]);
					}
					//console.log(this.stuInfo);
				} else {
					let nameFlag,
						charFlag,
						provinceFlag,
						cityFlag,
						typeFlag,
						levelFlag,
						natureFlag = false;
					for (let i = 0; i < this.uniList.length; i++) {
						if (
							this.uniList[i].province == this.search.searchProvince ||
							this.search.searchProvince == ""
						) {
							provinceFlag = true;
						} else {
							provinceFlag = false;
						}
						if (this.search.searchChar.length == 0) {
							charFlag = true;
						} else {
							let tempNum = 0;
							for (let j = 0; j < this.search.searchChar.length; j++) {
								this.uniList[i].char.forEach((item) => {
									//console.log(item);
									if (this.search.searchChar[j] == item) {
										tempNum++;
									}
								});
							}
							//console.log(tempNum);
							if (tempNum == this.search.searchChar.length) {
								charFlag = true;
							} else {
								charFlag = false;
							}
						}
						if (
							this.uniList[i].city == this.search.searchCity ||
							this.search.searchCity == ""
						) {
							cityFlag = true;
						} else {
							cityFlag = false;
						}
						if (
							this.uniList[i].type == this.search.searchType ||
							this.search.searchType == ""
						) {
							typeFlag = true;
						} else {
							typeFlag = false;
						}
						if (
							this.uniList[i].level == this.search.searchLevel ||
							this.search.searchLevel == ""
						) {
							levelFlag = true;
						} else {
							levelFlag = false;
						}
						if (
							this.uniList[i].nature == this.search.searchNature ||
							this.search.searchNature == ""
						) {
							natureFlag = true;
						} else {
							natureFlag = false;
						}
						if (
							this.uniList[i].name.indexOf(this.search.searchName) != -1 ||
							this.search.searchName == ""
						) {
							nameFlag = true;
						} else {
							nameFlag = false;
						}
						//console.log(nameFlag, charFlag, provinceFlag, cityFlag, typeFlag, levelFlag, natureFlag);
						if (
							nameFlag &&
							charFlag &&
							provinceFlag &&
							cityFlag &&
							typeFlag &&
							levelFlag &&
							natureFlag
						) {
							this.searchList.push(this.uniList[i]);
						}
						//  else {
						// 	this.searchList.splice(0, this.searchList.length);
						// }
					}
				}
				this.listLoading = false;
				this.pageFn();
			},
			getAreaList(code) {
				let temp = [];
				if (typeof code === "undefined") {
					code = "111111";
				}
				console.log(code);
				let showNull = {
					code: '无',
					city: null,
				};
				temp.push(showNull);
				if (code.substr(3, 3) == "000") {
					//城市列表
					for (let i in cityData[code]) {
						let o = {
							code: i,
							city: cityData[code][i],
						};
						temp.push(o);
					}
					console.log(this.city);
				} else if (code.substr(4, 2) == "00") {
					//县区列表
					for (let i in cityData[code]) {
						let o = {
							code: i,
							county: cityData[code][i],
						};
						temp.push(o);
					}
				} else {
					for (let key in cityData.province) {
						cityData.province[key].map((province) => {
							temp.push(province);
						});
					}
				}
				//console.log(temp);
				return temp;
			},
		},
		computed: {
			total() {
				return this.uniList.length;
			},
		},
		watch: {
			search: {
				handler() {
					this.getList();
				},
				deep: true,
			},
		},
	};
</script>

<style lang="scss">
	.uniSearch {
		background-color: white;
		margin: 15px;
		height: calc(100% - 30px);

		.top {
			padding: 5px 25px;
			border-bottom: 1px solid #eee;
			display: flex;
			line-height: 34px;

			h3 {
				margin: 0;
				flex: 1;
			}
		}

		.bottom {
			background: none;
			height: calc(100% - 50px);
			padding: 20px 10px;

			.infoSearch {
				margin-bottom: 10px;
				height: 100px;

				//display: flex;
				.searchName,
				.el-select,
				.el-cascader {
					width: 70%;
					margin-bottom: 20px;
				}
			}

			.el-table {
				.el-tag {
					margin-right: 5px;
				}
			}
		}
	}
</style>
